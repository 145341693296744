import React from 'react'
import Layout from '../../components/layout'
import Menu from '../../components/Menu'
import Footer from '../../components/Footer'
import Title from '../../components/Share/Title'
import Image from '../../components/Lendis/Image'
import { Link } from 'gatsby'
import Button from '../../components/Share/Button'
import Carousel from '../../components/Carousel'
import ConsumerCarousel from '../../components/Share/ConsumerCarousel'
import Header from '../../components/Header'
import BreadCrumb from '../../components/Share/BreadCrumb'
const breadcrumb = [
  {
    link: '/',
    title: 'Home',
  },
  {
    title: 'Büroplanung',
  },
]

const OfficePlanning = () => {
  const top_image =
    process.env.CLOUDINARY_URL +
    '/f_auto/website/office_planning/45_architecture-1857175_1920_msckmp.jpg'
  const obj_1_image =
    process.env.CLOUDINARY_URL +
    '/f_auto,w_1024/website/office_planning/46_person-holding-black-pen-1109541_rnpoxp.jpg'
  const obj_2_image =
    process.env.CLOUDINARY_URL +
    '/f_auto,w_1024/website/office_planning/50_white-and-black-desk-calculator-on-white-graphing-paper-159804_ndyzyo.jpg'
  const obj_3_image =
    process.env.CLOUDINARY_URL +
    '/f_auto,w_1024/website/office_planning/54_digital_office_management_-_dashboard_b7uizn.png'
  return (
    <Layout>
      <Header page="office-planning" />
      <Menu />

      <div
        className="big-background-image pb-8  bg-lendis-black bg-no-repeat bg-top pt-40 px-3"
        style={{ backgroundImage: `url(${top_image})` }}
      >
        <div className="container mx-auto text-white">
          <h1 className="font-bold lg:text-6xl px-8 text-3xl mb-5">Büroplanung</h1>

          <p className="font-semibold lg:text-2xl mb-10 mb-5 px-8 text-xl">
            Mehr als nur die Ausstattung
          </p>
        </div>

        <div className="bg-white container mx-auto">
          <div className="px-2">
            <BreadCrumb breadCrumbItems={breadcrumb} />
          </div>
          <div className="px-12">
            <Title
              title="Unsere Planungsservices"
              header="Büroplanung"
              right
              titleSize="text-3xl"
            />
          </div>

          <div className="bg-white px-8 md:px-0 md:pb-10">
            <div className="flex flex-wrap">
              <div className="w-full lg:w-1/3 md:px-6 py-6 text-center">
                <Image
                  className="mx-auto"
                  noChange
                  src="website/office_planning/60_Digitales_Office_Management_tpreao.svg"
                  alt="office_planning"
                />
                <h3 className="text-2xl text-center pt-8">Digitales Office Management</h3>
                <p className="text-center pt-4">Steuern Sie Ihr Büro komplett digital</p>
              </div>

              <div className="w-full lg:w-1/3 md:px-6 py-6 text-center">
                <Image
                  className="mx-auto"
                  noChange
                  src="website/office_planning/58_Grundrissplanung_lltaxu.svg"
                  alt="Grundrissplanung"
                />
                <h3 className="text-2xl text-center pt-8">Grundrissplanung</h3>
                <p className="text-center pt-4">Vom Grundriss zum fertigen Büro mit Lendis</p>
              </div>

              <div className="w-full lg:w-1/3 md:px-6 py-6 text-center">
                <Image
                  className="mx-auto"
                  noChange
                  src="website/office_planning/59_Kostencheck_yni4jm.svg"
                  alt="office_planning"
                />
                <h3 className="text-2xl text-center pt-8">Kostencheck</h3>
                <p className="text-center pt-4">Was sollte der Betrieb Ihres Büros kosten?</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-lendis-black">
        <div className="container mx-auto pt-12">
          <div className="bg-white container lg:flex mx-auto">
            <div
              className="lg:w-1/2 bg-cover"
              style={{ minHeight: '20rem', backgroundImage: `url(${obj_3_image})` }}
            />
            <div className="lg:mb-0 lg:mt-0 lg:pt-8 lg:w-1/2 m-6 pb-12 pt-12 px-6">
              <div className="py-4">
                <div className="flex items-center mb-4">
                  <p className="mr-4 text-lendis-main">01</p>
                  <hr className="border-t-2 w-20" />
                </div>
              </div>
              <h4 className="font-medium my-2 text-2xl">Digitales Office Management</h4>
              <p className="leading-loose text-base">
                Steuern Sie zukünftig alle Aspekte des Office Managements bequem digital über unser
                intuitives Dashboard.
              </p>
              <h5 className="font-medium my-2 pt-6 text-xl">So funktioniert’s</h5>

              <div className="flex flex-wrap">
                <div className="w-full lg:w-10/12">
                  <div className="items-center flex text-left leading-loose py-2">
                    <Image
                      className="w-8"
                      noChange
                      src="website/office_planning/55_shopping-cart_lildtc.svg"
                      alt="shopping-cart"
                    />
                    <p className="pl-5 ">
                      Bestellen Sie Ausstattung, Services und Software einfach über wenige Klicks.
                    </p>
                  </div>
                  <div className="items-center flex text-left leading-loose py-2">
                    <Image
                      className="w-8"
                      noChange
                      src="website/office_planning/56_chart_xocmof.svg"
                      alt="chart_xocmof"
                    />
                    <p className="pl-5 ">
                      Behalten Sie jeder Zeit die volle Kontrolle über laufende Verträge und alle
                      Ausgaben.
                    </p>
                  </div>
                  <div className="items-center flex text-left leading-loose py-2">
                    <Image
                      className="w-8"
                      noChange
                      src="website/office_planning/57_home_ljd1ml.svg"
                      alt="gesamte Office-Management"
                    />
                    <p className="pl-5 ">
                      Testen Sie jetzt das erste digitale Tool für das gesamte Office-Management.
                    </p>
                  </div>
                  <div className="items-center flex text-left py-2">
                    <Link to="/bueroplanung/digital-office-management/">
                      <Button className="lg:w-76" arrow={true} text="ZUM BETA-TEST ANMELDEN" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto pt-12">
          <div className="bg-white container lg:flex mx-auto flex-row-reverse">
            <div
              className="lg:w-1/2 bg-cover"
              style={{ minHeight: '20rem', backgroundImage: `url(${obj_1_image})` }}
            />
            <div className="lg:mb-0 lg:mt-0 lg:pt-8 lg:w-1/2 m-6 pb-12 pt-12 px-6">
              <div className="py-4">
                <div className="flex items-center mb-4">
                  <p className="mr-4 text-lendis-main">02</p>
                  <hr className="border-t-2 w-20" />
                </div>
              </div>
              <h4 className="font-medium my-2 text-2xl">Grundrissplanung</h4>
              <p className="leading-loose text-base">
                Wir bieten nicht nur die Ausstattung für Ihr Büro sondern übernehmen auf Wunsch auch
                schon die Interior-Planung.
              </p>
              <h5 className="font-medium my-2 pt-6 text-xl">So funktioniert’s</h5>

              <div className="flex flex-wrap">
                <div className="w-full lg:w-10/12">
                  <div className="items-center flex text-left leading-loose py-2">
                    <Image
                      className="w-8"
                      noChange
                      src="website/office_planning/47_contact_zx6s9g.svg"
                      alt="office_planning contact"
                    />
                    <p className="pl-5 ">
                      Lassen Sie uns Grundriss, Mitarbeiterzahl und individuelle Vorstellungen
                      zukommen.
                    </p>
                  </div>
                  <div className="items-center flex text-left leading-loose py-2">
                    <Image
                      className="w-8"
                      noChange
                      src="website/office_planning/48_sketch_dv1pjb.svg"
                      alt="office_planning sketch"
                    />
                    <p className="pl-5 ">
                      Sie erhalten von unserem erfahrenen Team eine individuelle Planung – ganz
                      unverbindlich.
                    </p>
                  </div>
                  <div className="items-center flex text-left leading-loose py-2">
                    <Image
                      className="w-8"
                      noChange
                      src="website/office_planning/49_desk_ejkf4f.svg"
                      alt="office_planning desk"
                    />
                    <p className="pl-5 ">
                      Beauftragen Sie Lendis auch mit der Umsetzung, sparen Sie sogar die
                      Planungsgebühr.
                    </p>
                  </div>
                  <div className="items-center flex text-left py-2">
                    <a rel="noopener noreferrer" href="https://rent.lendis.de/bueroplanung/">
                      <Button arrow={true} text="JETZT ANFRAGEN" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto pt-12">
          <div className="bg-white container lg:flex mx-auto">
            <div
              className="lg:w-1/2 bg-cover"
              style={{ minHeight: '20rem', backgroundImage: `url(${obj_2_image})` }}
            />
            <div className="lg:mb-0 lg:mt-0 lg:pt-8 lg:w-1/2 m-6 pb-12 pt-12 px-6">
              <div className="py-4">
                <div className="flex items-center mb-4">
                  <p className="mr-4 text-lendis-main">03</p>
                  <hr className="border-t-2 w-20" />
                </div>
              </div>
              <h4 className="font-medium my-2 text-2xl">Kostencheck</h4>
              <p className="leading-loose text-base">
                Machen Sie jetzt den Kostencheck – wie viel kostet Ihr Büro? Zahlen Sie zuviel für
                Ihren Arbeitsplatz?
              </p>
              <h5 className="font-medium my-2 pt-6 text-xl">So funktioniert’s</h5>

              <div className="flex flex-wrap">
                <div className="w-full lg:w-10/12">
                  <div className="items-center flex text-left leading-loose py-2">
                    <Image
                      className="w-8"
                      noChange
                      src="website/office_planning/51_shield_mf4nzv.svg"
                      alt="office_planning derzeitiges"
                    />
                    <p className="pl-5 ">
                      Machen Sie unseren kostenlosen Check – ob für Ihr geplantes oder derzeitiges
                      Büro.
                    </p>
                  </div>
                  <div className="items-center flex text-left leading-loose py-2">
                    <Image
                      className="w-8"
                      noChange
                      src="website/office_planning/52_wedding-planning_jft9lg.svg"
                      alt="wedding-planning"
                    />
                    <p className="pl-5 ">
                      Nennen Sie uns einige Eckdaten wie Bürogröße, Mitarbeiterzahl,
                      Ausstattungswünsche und individuelle Bedürfnisse.
                    </p>
                  </div>
                  <div className="items-center flex text-left leading-loose py-2">
                    <Image
                      className="w-8"
                      noChange
                      src="website/office_planning/53_accounting_jtlymj.svg"
                      alt="accounting"
                    />
                    <p className="pl-5 ">
                      Erfahren Sie, wie Sie bei den Kosten für Ihr Büro sparen können – ganz
                      unverbindlich.
                    </p>
                  </div>
                  <div className="items-center flex text-left py-2">
                    <Link to="/bueroplanung/kostencheck/">
                      <Button className="lg:w-72" arrow={true} text="JETZT KOSTEN CHECKEN" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-lendis-black">
        <div className="pt-10 px-10 container mx-auto">
          <Title
            bgColor="bg-lendis-black"
            title="Unsere Produkte"
            header="ANGEBOT"
            bgDark
            right
            titleSize="text-3xl"
          />
        </div>

        <div className=" px-10 container mx-auto pt-12">
          <Carousel
            slideToShow={3}
            useCarouselSize={true}
            dots={false}
            arrows={true}
            carousels={[
              {
                html: (
                  <div className="relative mx-4">
                    <Link to="/bueroeinrichtung/moebel/">
                      <div className="relative">
                        <Image
                          className=" opacity-50 w-96"
                          noChange
                          src="website/vertical/Images/footer/a.jpg"
                          alt="bueromoebel"
                        />
                      </div>
                      <div className="absolute bottom-0 left-0 mb-4 ml-8 ">
                        <h5 className="text-white font-bold text-2xl">Büromöbel</h5>
                      </div>
                    </Link>
                  </div>
                ),
              },
              {
                html: (
                  <div className="relative mx-4">
                    <Link to="/bueroeinrichtung/akustikloesungen/">
                      <div className="relative">
                        <Image
                          className=" opacity-50 w-96"
                          noChange
                          src="website/vertical/Images/footer/b.jpg"
                          alt="akustik-loesungen"
                        />
                      </div>
                      <div className="absolute bottom-0 left-0 mb-4 ml-8 ">
                        <h5 className="text-white font-bold text-2xl">Akustik-Lösungen</h5>
                      </div>
                    </Link>
                  </div>
                ),
              },
              {
                html: (
                  <div className="relative mx-4">
                    <Link to="/bueroeinrichtung/telefonboxen/">
                      <div className="relative">
                        <Image
                          className=" opacity-50 w-96"
                          noChange
                          src="website/vertical/Images/footer/c.jpg"
                          alt="telefonboxen"
                        />
                      </div>
                      <div className="absolute bottom-0 left-0 mb-4 ml-8 ">
                        <h5 className="text-white font-bold text-2xl">Telefonboxen</h5>
                      </div>
                    </Link>
                  </div>
                ),
              },
              {
                html: (
                  <div className="relative mx-4">
                    <Link to="/bueroeinrichtung/pflanzen/">
                      <div className="relative">
                        <Image
                          className=" opacity-50 w-96"
                          noChange
                          src="website/vertical/Images/footer/d.jpg"
                          alt="pflanzen"
                        />
                      </div>
                      <div className="absolute bottom-0 left-0 mb-4 ml-8 ">
                        <h5 className="text-white font-bold text-2xl">Pflanzen</h5>
                      </div>
                    </Link>
                  </div>
                ),
              },
              {
                html: (
                  <div className="relative mx-4">
                    <Link to="/elektronik/">
                      <div className="relative">
                        <Image
                          className=" opacity-50 w-96"
                          noChange
                          src="website/vertical/Images/footer/e.jpg"
                          alt="elektronik"
                        />
                      </div>
                      <div className="absolute bottom-0 left-0 mb-4 ml-8 ">
                        <h5 className="text-white font-bold text-2xl">Elektronik</h5>
                      </div>
                    </Link>
                  </div>
                ),
              },
              {
                html: (
                  <div className="relative mx-4">
                    <Link to="/software/">
                      <div className="relative">
                        <Image
                          className=" opacity-50 w-96"
                          noChange
                          src="website/vertical/Images/footer/f.jpg"
                          alt="software"
                        />
                      </div>
                      <div className="absolute bottom-0 left-0 mb-4 ml-8 ">
                        <h5 className="text-white font-bold text-2xl">Software</h5>
                      </div>
                    </Link>
                  </div>
                ),
              },
              {
                html: (
                  <div className="relative mx-4">
                    <Link to="/services/">
                      <div className="relative">
                        <Image
                          className=" opacity-50 w-96"
                          noChange
                          src="website/vertical/Images/footer/fjycjamv30imkmcrfb5m.jpg"
                          alt="services"
                        />
                      </div>
                      <div className="absolute bottom-0 left-0 mb-4 ml-8 ">
                        <h5 className="text-white font-bold text-2xl">Services</h5>
                      </div>
                    </Link>
                  </div>
                ),
              },
            ]}
          />
        </div>

        <ConsumerCarousel />
      </div>

      <Footer />
    </Layout>
  )
}

export default OfficePlanning
